import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout/Layout'

let pageDescription = ['Treeland Trails items for sale'].join('\n')

let pageKeywords = [''].join('\n')

const Shop = () => {
  return (
    <Layout>
      <Helmet
        title="Shop"
        meta={[
          { name: 'description', content: pageDescription },
          { name: 'keywords', content: pageKeywords },
        ]}
      ></Helmet>
      <div id="shop" className="page-content">
        <h2>Shop</h2>
        <p>
          <p>show all or filter by category</p>
          <ul>
            <li>Forest items for sale: firewood, biochar, cabins</li>
            <li>Ranching items for sale: animals, meats</li>
            <li>Products for sale: t-shirts, etc</li>
            <li>Services/Equipment</li>
          </ul>
        </p>
      </div>
    </Layout>
  )
}

Shop.propTypes = {}

export default Shop
